// Parse the Cookie
function getCookie(cname) {
	var name = cname + '=';
	var decodedCookie = decodeURIComponent(document.cookie);
	var ca = decodedCookie.split(';');
	for (var i = 0; i < ca.length; i++) {
		var c = ca[i];
		while (c.startsWith(' ')) {
			c = c.substring(1);
		}
		if (c.indexOf(name) === 0) {
			return c.substring(name.length, c.length);
		}
	}
	return '';
}

function OptanonWrapper() {
	setWistiaTracking();
	setScoopitScripts();
	setPardotUTMTags();


	OneTrust.OnConsentChanged(function (e) {
		setWistiaTracking();
		setScoopitScripts();
		setPardotUTMTags();

		// Reload only if consent is made
		const acceptedAny = OnetrustActiveGroups.split(',').filter(group => Boolean(group) && group !== 'C0001');
		if (acceptedAny.length > 0) {
			window.location.reload();
		}
	});

	function setWistiaTracking() {
		const isEnabled = OnetrustActiveGroups.includes('C0001') && OnetrustActiveGroups.includes('C0003');

		window._wq = window._wq || [];
		window._wq.push(function (W) {
			W.consent(isEnabled);
		});
	}

	function setPardotUTMTags() {
		// Return early when cookies are set, because it is already handled by GTM script
		const LOCALSTORAGE_KEY = '__gtm_campaign_url';

		// Save UTM tags to localstorage if there is any
		const UTM_TAGS = [
			'utm_source',
			'utm_medium',
			'utm_campaign',
			'utm_term',
			'utm_content',
			'utm_id',
			'gclid',
		];
		const urlParams = new URLSearchParams(document.location.search);
		const paramsKeys = Array.from(urlParams.keys());
		const hasUtmTags = UTM_TAGS.some((tag) => paramsKeys.includes(tag));
		const url = document.location.toString();

		if (hasUtmTags) {
			localStorage.setItem(LOCALSTORAGE_KEY, url);
		}

		if (getCookie(LOCALSTORAGE_KEY)) return;

		// Check if there are UTM tags in localstorage
		// Add them to the pardot forms
		const gtmCampaignCookie = localStorage.getItem(LOCALSTORAGE_KEY);
		if (gtmCampaignCookie) {
			const gtmParams = new URLSearchParams(new URL(gtmCampaignCookie).search);
			const iframes = document.querySelectorAll('iframe[src^="https://www2.ecovadis.com/"]');
			Array.from(iframes).forEach(function (iframe) {
				const src = iframe.src;
				const srcURL = new URL(src);
				const iframeParams = new URLSearchParams(srcURL.search);
				const iframeNewParams = new URLSearchParams(
					[].concat(
						Array.from(iframeParams.entries()),
						Array.from(gtmParams.entries()),
					),
				).toString();

				iframe.src = srcURL.origin + srcURL.pathname + '?' + iframeNewParams;
			});
		}
	}

	//decide if the scoopit fallback should be shown
	function setScoopitScripts() {
		const isEnabled = OnetrustActiveGroups.includes('C0004');
		var scoopit_module = $('.scoopit-module');
		if (scoopit_module.length) {
			if (isEnabled) {
				scoopit_module.removeClass('scoopit-disabled');
			} else {
				scoopit_module.addClass('scoopit-disabled');
			}
		}
	}
}

function isTargetingAccepted() {
	if (getCookie('OptanonConsent')) {
		var params = new URLSearchParams(Cookies.get('OptanonConsent'));

		if (params.get('groups') && params.get('groups').includes('C0004:1')) {
			return true;
		}
	}

	//let go on develop
	return document.location.href.includes('ecovadis-www.flyhigh.pro');
}
